import fnDebounce from 'debounce'
import { watch } from 'vue'
import { useRoute } from 'vue-router'

export const debounce = fnDebounce

export { fmt } from './formatters'

/**
 * Watch the route full path, and trigger callback when query / params change
 * but don't trigger when route name change (move away to other page).
 * Optional watchSource to watch just specific part of the route (not fullPath).
 */
export const whenRouteChange = (callback: () => unknown, watchSource?: () => unknown) => {
  const route = useRoute()
  const routeName = route.name // cache route name
  watch(
    watchSource ?? (() => route.fullPath),
    () => {
      if (route.name === routeName) {
        callback()
      }
    },
    { immediate: true },
  )
}

export const useDocumentOverflow = () => {
  const disableOverflow = () => {
    document.body.style.overflowY = 'hidden'
  }
  const reenableOverflow = () => {
    // delay because of vue lightbox
    setTimeout(() => {
      document.body.style.overflowY = ''
    }, 200)
  }
  return {
    disableOverflow,
    reenableOverflow,
  }
}

export const useEscListener = (callback: () => void) => {
  return (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      callback()
    }
  }
}
