<script setup lang="ts">
  import AppToast from '@/components/AppToast.vue'
  import { useApi } from '@/functions/api'

  const api = useApi()

  api.GET('/sanctum/csrf-cookie')
</script>

<template>
  <router-view />
  <app-toast />
</template>
