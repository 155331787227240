// styles
import '@unocss/reset/tailwind-compat.css'
import 'virtual:uno.css'
import '@/assets/style.css'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import globalComponents from '@/components'
import globalDirectives from '@/directives'
import router from '@/routes'
import sentry from '@/services/sentry'
import App from './App.vue'

const app = createApp(App)
  .use(router)
  .use(sentry, router)
  .use(createPinia())
  .use(globalComponents)
  .use(globalDirectives)

app.mount('#app')
