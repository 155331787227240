export default {
  path: '/settings',
  name: 'settings',
  component: () => import('@/views/Settings/Index.vue'),
  children: [
    {
      path: 'profile',
      name: 'setting profile',
      component: () => import('@/views/Settings/Profile.vue'),
    },
    {
      path: 'email',
      name: 'setting email',
      component: () => import('@/views/Settings/Email.vue'),
    },
    // TODO feature to set gmail account and disable invite
    // {
    //   path: 'sync-calendar',
    //   name: 'setting sync calendar',
    //   component: () => import('@/views/Settings/SyncCalendar.vue'),
    // },
    {
      path: 'password',
      name: 'setting password',
      component: () => import('@/views/Settings/Password.vue'),
    },
  ],
}
